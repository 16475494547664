import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
 import ServiceHomePage from './ServiceHomePage.js';
import MateriaList from './MateriaList.js';
 import MaterialAmount from './MaterialAmount.js';
import './index.css'; 
function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<ServiceHomePage />} />
        <Route path="/material-amount" element={<MaterialAmount />} />
        <Route path="/material-list" element={<MateriaList />} />
        </Routes>
    </Router>
  );
}

export default App;
