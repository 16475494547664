// HomePage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ServiceHomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-3xl mb-6 font-bold">Welcome to the Material Management System</h1>
      
      <div className="flex flex-col space-y-6 items-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold mb-2">Go to MateriaList</h2>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-green-600"
            onClick={() => navigate('/material-list')}
          >
            Materia List
          </button>
        </div>
        
        <div className="text-center">
          <h2 className="text-xl font-semibold mb-2">Go to MaterialAmount in Shop</h2>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={() => navigate('/material-amount')}
          >
            Material Amount
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceHomePage;
