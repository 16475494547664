import { useState, useRef, useEffect } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import './customScrollbar.css'; // Import the custom CSS
import { base64LathaFont,base64RoboteFont } from './fonts'; // Import the base64 font string

const MateriaList = () => {
  const [groceryItems, setGroceryItems] = useState([]);
  const [username, setUsername] = useState('');
  const [itemName, setItemName] = useState('');
  const [title, setTitle] = useState('');
  const [subQuantity, setSubQuantity] = useState('');
  const [quantity, setQuantity] = useState('');
  const [subUnit, setSubUnit] = useState('');
  const [unit, setUnit] = useState('');
//   const [rupees, setRupees] = useState(''); // New Rupees field
  const [isRecording, setIsRecording] = useState(false);
  const [recordingField, setRecordingField] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [customUnit, setCustomUnit] = useState('');
  const itemNameRef = useRef(null); // Step 2: Create a reference
  const [error, setError] = useState('');

  
  
  let recognition;
  

  useEffect(() => {
    itemNameRef.current.focus(); // Step 3: Set focus on mount
  }, []); // Empty dependency array to run only once on mount

  const handleBlur = () => {
    if (!itemName.trim()) {
      setError("Item Name is required.");
      // setItemName(""); // Reset input or set to a default value
    } else {
      setError(""); // Clear the error if input is valid
    }
  };


  // Voice input logic with language selection (Tamil or English)
  const tamilNumberMap = {
    'பூஜ்ஜியம்': '0',
    'ஒன்று': '1',
    'இரண்டு': '2',
    'மூன்று': '3',
    'நான்கு': '4',
    'ஐந்து': '5',
    'ஆறு': '6',
    'ஏழு': '7',
    'எட்டு': '8',
    'ஒன்பது': '9',
    'பத்து': '10',
    // 'ச்': 'kg'

  
  };

  
  const handleVoiceInput = (field, language) => {
    if (isRecording) {
      recognition.abort();
      
      return;
    }
  
    if ('webkitSpeechRecognition' in window) {
      recognition = new window.webkitSpeechRecognition();
      recognition.lang = language; // Set language based on input (Tamil or English)
      recognition.continuous = false;
      recognition.interimResults = false;
  
      recognition.onstart = () => {
        setIsRecording(true);
        setRecordingField(field);
        console.log(`Voice recognition started for ${field}. Speak now...`);
      };
  
      recognition.onresult = (event) => {
        let transcript = event.results[0][0].transcript.trim();
        
        // Convert Tamil number words to digits if Tamil language is selected
        if (language === 'ta-IN') {
          transcript = tamilNumberMap[transcript] || transcript; // Check if the transcript is a Tamil number word
        }
  
        // Validate the voice input for the field
        if (field === 'itemName') {
          if (!transcript) {
            setError("Item Name is required."); // Set error if input is empty
          } else {
            setItemName(transcript);
            setError(''); // Clear error if input is valid
          }
        } else if (field === 'subQuantity') {
          setSubQuantity(transcript);
        } else if (field === 'quantity') {
          setQuantity(transcript);
        }
  
        console.log(`Recognized Text for ${field}:`, transcript);
      };  
      recognition.onerror = (event) => {
        let errorMessage;
        switch (event.error) {
          case 'no-speech':
            errorMessage = 'No speech detected. Please try again.';
            break;
          case 'audio-capture':
            errorMessage = 'No microphone detected. Please ensure your microphone is connected.';
            break;
          case 'not-allowed':
            errorMessage = 'Permission to use the microphone is denied. Please allow it in your browser settings.';
            break;
          default:
            errorMessage = `Error: ${event.error}`;
        }
        console.error("Voice recognition error:", event.error);
        alert(errorMessage);
      };
        
      recognition.onend = () => {
        setIsRecording(false);
        setRecordingField('');
        console.log("Voice recognition ended.");
      };
  
      recognition.start();
    } else {
      alert("Voice recognition is not supported in this browser. Please use Chrome.");
    }
  };
    
  const handleAddItem = () => {
    if (itemName) {
      const newItem = {
        id: Math.random(),
        name: itemName,
        subQuantity: `${subQuantity}${subUnit}`,
        quantity: `${quantity}${unit}`,
        // rupees: ` `, // Include Rupees in item details
      };
      setGroceryItems([...groceryItems, newItem]);
      setItemName('');
      setSubQuantity('');
      setQuantity('');
      setSubUnit('');
      setUnit('');
    //   setRupees('');
    }
  };

  const handleDeleteItem = (id) => {
    setGroceryItems(groceryItems.filter(item => item.id !== id));
  };

  const firstDeleteButtonRef = useRef(null);

  useEffect(() => {
    if (showDelete && firstDeleteButtonRef.current) {
      firstDeleteButtonRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showDelete]);


  const sanitizeFileName = (name) => {
    if (!name || name.trim() === '') {
      return 'N';  // Return 'N' if value is null or empty
    }
    return name
      .replace(/[^a-zA-Z0-9\u0B80-\u0BFF\s]/g, '')  // Remove special characters, except Tamil characters and spaces
      .replace(/\s+/g, '_')                         // Replace spaces with underscores
      .replace(/_+$/, '');                          // Remove trailing underscores
  };
  const downloadTableDataAsPdf = () => {
    const title = document.getElementById('pdfTitle') ? document.getElementById('pdfTitle').value : 'Material List';
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();

    pdfMake.vfs = {
      ...pdfFonts.pdfMake.vfs,
      'Latha.ttf': base64LathaFont,
       'Roboto.ttf': base64RoboteFont,
    };
  
    pdfMake.fonts = {
      Latha: {
        normal: 'Latha.ttf',
        bold: 'Latha.ttf',
        italics: 'Latha.ttf',
        bolditalics: 'Latha.ttf',
      },
      Roboto: {
        normal: 'Roboto.ttf',
        bold: 'Roboto.ttf',
        italics: 'Roboto.ttf',
        bolditalics: 'Roboto.ttf',
      }
    };
  

    const docDefinition = {
        content: [
          {
            text: title ? sanitizeFileName(title) : 'Material_List',
            style: 'header',
            margin: [0, 0, 0, 20]
        },
                    {
                table: {
                    headerRows: 1,
                    widths: ['auto', '*', 'auto', 'auto'],
                    body: [
                        [
                            { text: 'S.No', style: 'tableHeader' },
                            { text: 'Item', style: 'tableHeader' },
                            { text: 'SubQty', style: 'tableHeader' },
                            { text: 'Quantity', style: 'tableHeader' }
                        ],
                        ...groceryItems.map((item, index) => [
                            { text: index + 1, style: 'tableData' },
                            { text: sanitizeFileName(item.name), style: 'tableData' },
                            { text: sanitizeFileName(item.subQuantity), style: 'tableData' },
                            { text: sanitizeFileName(item.quantity), style: 'tableData' }
                        ]),
                        [
                            { text: ``, colSpan: 3, style: 'tableHeader', alignment: 'left' },
                            {}, // Empty cell for colSpan
                            {}, // Empty cell for colSpan
                            { text: '', style: 'tableHeader', alignment: 'right' }
                        ]
                    ]
                    
                },
                layout: {
                    fillColor: (rowIndex) => (rowIndex % 2 === 0 ? '#f9f9f9' : '#ffffff'),
                    hLineWidth: () => 1,
                    vLineWidth: () => 1,
                    hLineColor: () => '#cccccc',
                    vLineColor: () => '#cccccc',
                    paddingLeft: () => 10,
                    paddingRight: () => 10
                }
            },

            {
              text: `Name_Mobile: ${username}`,
              fontSize: 15,
              color: '#000000',

              // style: 'subheader',
              alignment: 'left',
              margin: [0, 20, 0, 0] // Adds margin to create space between the table and this text
          }

        ],
        styles: {
            header: {
                fontSize: 26,
                bold: true,
                color: 'purple',
                alignment: 'center',
                margin: [0, 0, 0, 10],
                font: 'Roboto' // Use Roboto for header
            },
            tableHeader: {
                fillColor: '#007BFF',
                color: '#ffffff',
                fontSize: 16,
                bold: true,
                alignment: 'center',
                font: 'Roboto' // Use Roboto for table header
            },
            tableData: {
                fontSize: 17,
                color: '#000000',
                alignment: 'center',
                margin: [0, 5],
                font: 'Latha' // Use Latha for table data
            },
            subheader: {
                fontSize: 12,
                color: '#333333',
                font: 'Latha' // Use Latha for subheaders
            }
        },
        defaultStyle: {
            font: 'Roboto', // Default to Roboto
            fontSize: 30,
        },
        footer: (currentPage, pageCount) => {
            return {
                columns: [
                    {
                        text: `Generated_On: ${formattedDate} at ${formattedTime}`,
                        alignment: 'left',
                        margin: [10, 0, 0, 0],
                        fontSize: 15,
                        color: '#000000'
                    },
                    {
                        text: `Page ${currentPage} of ${pageCount}`,
                        alignment: 'right',
                        margin: [0, 0, 10, 0],
                        fontSize: 15,
                        color: '##000000'
                    }
                ]
            };
        }
    };
    
    const pdfTitle = title || 'Material List';
    const pdfFileName = `${pdfTitle.replace(/\s+/g, '_')}_Dt : ${formattedDate}_Time : ${formattedTime}.pdf`;
    
    pdfMake.createPdf(docDefinition).download(pdfFileName);
  


};
  const toggleDelete = () => {
    setShowDelete(!showDelete);
  };
  return (
<div className="container mx-auto p-4">
  <h1 className="text-2xl mb-4 text-center">Add Material Items</h1>

  {/* Item Name and Voice Buttons */}
  <div className="flex flex-col md:flex-row items-center mb-4 gap-4">
    <input
      type="text"
      value={itemName}
      spellCheck="true"
      onChange={(e) => setItemName(e.target.value)}
      onBlur={handleBlur}
      ref={itemNameRef} // Step 2: Attach the ref to the input field
      placeholder="Item Name"
      className={`border p-2 rounded w-full md:w-1/3 ${error ? 'border-red-500' : ""}`}      required  // HTML5 required attribute

    />

    <div className="flex gap-2">
    <button
        onClick={() => handleVoiceInput('itemName', 'ta-IN')}
        className={`p-2 rounded w-full md:w-auto ${
          isRecording && recordingField === 'itemName' ? 'bg-red-500' : 'bg-blue-500'
        } text-white`}
      >
        🎤 {isRecording && recordingField === 'itemName' ? 'Stop Tamil' : 'Tamil Voice'}
      </button>
      <button
        onClick={() => handleVoiceInput('itemName', 'en-US')}
        className={`p-2 rounded w-full md:w-auto ${
          isRecording && recordingField === 'itemName' ? 'bg-red-500' : 'bg-green-500'
        } text-white`}
      >
        🎤 {isRecording && recordingField === 'itemName' ? 'Stop English' : 'English Voice'}
      </button>
    </div>
  </div>
  <div className="flex flex-col md:flex-row items-center gap-4 mb-4">
    <div className="flex w-full md:w-1/3">
      <input
        type="text"
        value={`${quantity}`}  
        spellCheck="true"
        onChange={(e) => setQuantity(e.target.value)}
        placeholder="Quantity"
        className="border p-2 rounded w-full md:w-1/1"
      />
<select
  value={unit}
  onChange={(e) => setUnit(e.target.value)}
  className="border p-2 rounded md:w-1/1"
>
  <option>Select Unit</option>
  <option value="Kg">Kg</option>
  <option value="Nos">Numbers</option>
  <option value="Rs">Rupees</option>
 <option value="Grams">Grams</option>
  <option value="Liters">Liters</option>
  <option value="custom">Custom</option>  {/* New Custom Option */}
</select>

{/* If custom unit is selected, show input field */}
{unit === 'custom' && (
  <input
    type="text"
    value={customUnit}
    onChange={(e) => setCustomUnit(e.target.value)}
    placeholder="Enter custom unit"
    className="border p-2 rounded w-full md:w-1/3 mt-2"
  />
)}
    </div>
    <div className="flex gap-2">
      <button
        onClick={() => handleVoiceInput('quantity', 'ta-IN')}
        className={`p-2 rounded w-full md:w-auto ${
          isRecording && recordingField === 'quantity' ? 'bg-red-500' : 'bg-blue-500'
        } text-white`}
      >
        🎤 {isRecording && recordingField === 'quantity' ? 'Stop Tamil' : 'Tamil Voice'}
      </button>
      <button
        onClick={() => handleVoiceInput('quantity', 'en-US')}
        className={`p-2 rounded w-full md:w-auto ${
          isRecording && recordingField === 'quantity' ? 'bg-red-500' : 'bg-green-500'
        } text-white`}
      >
        🎤 {isRecording && recordingField === 'quantity' ? 'Stop English' : 'English Voice'}
      </button>
    </div>
  </div>

    {/* Sub-Quantity with Dropdown and Voice Buttons */}
    <div className="flex flex-col md:flex-row items-center w-full gap-4 mb-4">
    <div className="flex w-full md:w-1/3">
    <input
        type="text"
        value={`${subQuantity}`}  
         spellCheck="true"
        onChange={(e) => setSubQuantity(e.target.value)}

        placeholder="Sub-Quantity"
        className="border p-2 rounded w-full"
      />
      <select
        value={subUnit}
        onChange={(e) => setSubUnit(e.target.value)}
        className="border p-2 rounded"
      >
        <option value="">Select Unit</option>
        <option value="Kg">Kg</option>
        <option value="Nos">Numbers</option>
        <option value="Rs">Rupees</option>
        <option value="Grams">Grams</option>
        <option value="Liters">Liters</option>
      </select>
    </div>
    <div className="flex  gap-2 m ">
      <button
        onClick={() => handleVoiceInput('subQuantity', 'ta-IN')}
        className={`p-2 rounded w-full md:w-auto ${
          isRecording && recordingField === 'subQuantity' ? 'bg-red-500' : 'bg-blue-500'
        } text-white`}
      >
        🎤 {isRecording && recordingField === 'subQuantity' ? 'Stop Tamil' : 'Tamil Voice'}
      </button>
      <button
        onClick={() => handleVoiceInput('subQuantity', 'en-US')}
        className={`p-2 rounded w-full md:w-auto ${
          isRecording && recordingField === 'subQuantity' ? 'bg-red-500' : 'bg-green-500'
        } text-white`}
      >
        🎤 {isRecording && recordingField === 'subQuantity' ? 'Stop English' : 'English Voice'}
      </button>
    </div>
  </div>

    {/* Username and Title Inputs */}
    <div className="flex flex-row items-center gap-4 mt-4">
  <input
    type="text"
    value={title}
    spellCheck="true"
    onChange={(e) => setTitle(e.target.value)}
    id="pdfTitle"
    placeholder="Enter List Title"
    className="border p-2 rounded text-center w-1/2"
  />
  <input
    type="text"
    value={username}
    spellCheck="true"
    onChange={(e) => setUsername(e.target.value)}
    placeholder="Name & Mobile if Adr"
    className="border p-2 rounded text-center w-1/2"
  />
</div>



  <button
    onClick={handleAddItem}
    className="bg-purple-500 text-white p-2 rounded hover:bg-blue-600 w-full md:w-auto mb-4"
  >
    Add Item
  </button>
  

  {/* Table of Grocery Items */}
  
      <div className="overflow-x-scroll w-full mb-4 custom-scrollbar">
        <div className="min-w-full bg-white border border-gray-300 mb-4">
          <table className="min-w-full bg-white border border-gray-300 mb-4">
            <thead>
              <tr>
                <th className="px-4 py-2 border">S.No</th>
                <th className="px-4 py-2 border">Items</th>
                <th className="px-4 py-2 border">Sub-Qty</th>
                <th className="px-4 py-2 border">Qty</th>
                {/* <th className="px-4 py-2 border">Rs</th> */}
                {showDelete && <th className="px-4 py-2 border text-red-500">Del</th>}
              </tr>
            </thead>
            <tbody>
              {groceryItems.map((item, index) => (
                <tr key={item.id} className="border-t">
                  <td className="px-4 py-2 border">{index + 1}</td>
                  <td className="px-4 py-2 border">{item.name }</td>
                  <td className="px-4 py-2 border">{item.subQuantity}</td>
                  <td className="px-4 py-2 border">{item.quantity }</td>
                  {/* <td className="px-4 py-2 border">{item.rupees}</td> */}
                  {showDelete && (
                    <td className="px-4 py-2 border text-right">
                      <button
                        onClick={() => handleDeleteItem(item.id)}
                        className="bg-red-500 text-white p-2 rounded hover:bg-red-600 w-full md:w-auto"
                        ref={index === 0 ? firstDeleteButtonRef : null}
                      >
                        Delete
                      </button>
                    </td>
                  )}
                </tr>
              ))}
   <tr className="font-bold">
            {/* <td className="px-4 py-2 border text-right" colSpan="4">
              Total
            </td> */}
            {/* <td className="px-4 py-2 border">{totalRupees}</td> */}
            {showDelete && <td className="px-4 py-2 border"></td>}
          </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Toggle Button to Show/Hide Delete */}
      <div className="flex justify-center gap-4 mt-4">
      <button
          onClick={toggleDelete}
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
          >
          {showDelete ? 'Hide Delete' : 'Show Delete'}
        </button>
      </div>
    
 
      {/* Download Button */}
      <div className=' gap-4 mt-4 '>
      <button
        onClick={downloadTableDataAsPdf}
        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 w-full md:w-auto"
      >
        Download PDF
      </button>
      </div>
    </div>
  );
};

export default MateriaList;
