import React, { useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import Tesseract from 'tesseract.js';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { base64LathaFont } from './fonts';

// Set the workerSrc property to enable PDF.js to function properly
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

// Register fonts for pdfMake
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function MaterialAmount() {
  const [dataTable, setDataTable] = useState([]);
  const [amounts, setAmounts] = useState({});
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [shopName, setShopName] = useState(''); // State for shop name
  const [shopNumber, setShopNumber] = useState(''); // State for shop Google Pay number

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        const typedarray = new Uint8Array(fileReader.result);
        await extractTextFromPdf(typedarray);
      };
      fileReader.readAsArrayBuffer(file);
    } else {
      setError('Please upload a valid PDF file.');
    }
  };

  const extractTextFromPdf = async (data) => {
    setIsLoading(true);
    try {
      const pdf = await pdfjsLib.getDocument(data).promise;
      const numPages = pdf.numPages;
      let allData = [];

      for (let pageNum = 1; pageNum <= numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({ canvasContext: context, viewport }).promise;

        const result = await Tesseract.recognize(
          canvas.toDataURL(),
          'eng+tam', // Change to 'tam' for Tamil if needed
          {
            logger: (m) => console.log(m),
          }
        );

        // Split the extracted text into lines and then into words
        const lines = result.data.text.split('\n').filter(line => line.trim() !== '');
        lines.forEach(line => {
          const columns = line.split(/\s+/); // Split by whitespace
          allData.push(columns);
        });
      }

      setDataTable(allData);
      setError('');
    } catch (err) {
      setError('Failed to read PDF: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAmountChange = (rowIndex, value) => {
    setAmounts({
      ...amounts,
      [rowIndex]: parseFloat(value) || 0, // Ensure the value is a number
    });
  };

  return (
    <div className="App p-6">
   <h1 className="text-2xl font-bold mb-4">PDF Table with Amount Column</h1>   
      <input type="file" accept="application/pdf" onChange={handleFileChange} className="block w-full text-sm text-gray-500 cursor-pointer p-2 mb-4" />
      {error && <p className="text-red-500">{error}</p>}
      {isLoading && <p>Loading...</p>}
      <h2 className="text-xl font-semibold mb-2">Extracted Data:</h2>
      <div className="overflow-x-auto">
        <Table data={dataTable} onAmountChange={handleAmountChange} amounts={amounts} shopName={shopName} shopNumber={shopNumber} setShopName={setShopName} setShopNumber={setShopNumber} />
      </div>
    </div>
  );
}

// Table Component to display data
const Table = ({ data, onAmountChange, amounts, shopName, shopNumber, setShopName, setShopNumber }) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.vfs['Latha.ttf'] = base64LathaFont;

  pdfMake.fonts = {
    Latha: {
      normal: 'Latha.ttf',
      bold: 'Latha.ttf',
      italics: 'Latha.ttf',
      bolditalics: 'Latha.ttf',
    },
  };

  if (data.length === 0) return null;

  const firstRow = data[0];
  const lastTwoRows = data.slice(-2);
  const filteredData = data.slice(2, -2);

  const generatePDF = () => {
    if (!firstRow || !filteredData || !lastTwoRows) {
      console.error('Data for PDF is missing');
      return;
    }
  
    const calculateTotalAmount = () => {
      return Object.values(amounts).reduce((sum, amount) => sum + amount, 0);
    };
  
    const docDefinition = {
      content: [
        {
          columns: firstRow.map((cell) => ({
            text: cell || '', // Ensure no undefined values
            fontSize: 18, // Directly set the font size
            alignment: 'center', // Directly set the text alignment
            margin: [0, 5, 0, 5], // Optional: set margin for the cell
          })),
        },
                        {
          text: 'Table',
          style: 'header',
          margin: [0, 20],
        },
        {
          table: {
            widths: ['auto', 'auto', 'auto', 'auto', 'auto'], // Specify column widths
            body: [
              [
                { text: 'Sino', style: 'tableHeader' },
                { text: 'Item', style: 'tableHeader' },
                { text: 'SubQty', style: 'tableHeader' },
                { text: 'Qty', style: 'tableHeader' },
                { text: 'Amount', style: 'tableHeader' },
              ],
              ...filteredData.map((row, rowIndex) => [
                { text: rowIndex + 1 || '', style: 'cell' },
                { text: row[1] || '', style: 'cell' },
                { text: row[2] || '', style: 'cell' },
                { text: row[3] || '', style: 'cell' },
                { text: amounts[rowIndex] || '', style: 'cell' },
              ]),
            ],
          },
          layout: {
            hLineWidth: () => 1,
            vLineWidth: () => 1,
            hLineColor: () => '#E5E7EB', // Tailwind Gray-300
            vLineColor: () => '#E5E7EB', // Tailwind Gray-300
            paddingLeft: () => 10,
            paddingRight: () => 10,
            paddingTop: () => 8,
            paddingBottom: () => 8,
          },
        },
        { text: `Total Amount: ${calculateTotalAmount()}`, style: 'totalAmount' },
  
        ...lastTwoRows.map((cell) => ({
            text: cell || '', // Ensure no undefined values
            style: 'cell',
          })),
        {
          text: 'Shop Details',
          style: 'header',
          margin: [0, 20],
        },
        {
          columns: [
            { text: `Shop Name: ${shopName}`, style: 'cell' },
            { text: `Google Pay Number: ${shopNumber}`, style: 'cell' },
          ],
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 10],
        },
        cell: {
          fontSize: 13,
        },
        tableHeader: {
          fillColor: '#228B22', // Tailwind Green-600
          color: '#FFFFFF', // White font color
          bold: true,
          fontSize: 12,
          margin: [5, 5, 50, 4], // Adjusted margins for uniformity
          alignment: 'center', // Center-align the text
        },
                totalAmount: {
          fontSize: 14,
          bold: true,
          margin: [0, 10],
          alignment: 'right', // Align text to the right
        },
              },
      defaultStyle: {
        font: 'Latha',
      },
    };
  
    pdfMake.createPdf(docDefinition).download('document.pdf');
  };
  
  const calculateTotalAmount = () => {
    return Object.values(amounts).reduce((sum, amount) => sum + amount, 0);
  };

  return (
    <div>
      {firstRow && (
        <div className="mb-5 p-4 text-center">
          <p className="text-3xl">
            {firstRow.map((cell, index) => (
              <span key={index} className="mr-2">
                {cell}
              </span>
            ))}
          </p>
        </div>
      )}

      <table className="table-auto w-full border-collapse">
        <thead>
          <tr>
            <th className="border p-2 bg-green-600 text-white">Sino</th>
            <th className="border p-2 bg-green-600 text-white">Item</th>
            <th className="border p-2 bg-green-600 text-white">SubQty</th>
            <th className="border p-2 bg-green-600 text-white">Qty</th>
            <th className="border p-2 bg-green-600 text-white">Amount</th>
          </tr>
        </thead>
        <tbody>
          {data.slice(2, -2).map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td className="border p-2">{rowIndex + 1}</td>
              <td className="border p-2">{row[1]}</td>
              <td className="border p-2">{row[2]}</td>
              <td className="border p-2">{row[3]}</td>
              <td className="border p-2">
                <input
                  type="number"
                  placeholder='Add Amount'
                  value={amounts[rowIndex] || ''}
                  onChange={(e) => onAmountChange(rowIndex, e.target.value)}
                  className="border p-1 w-full"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h3 className="text-lg font-semibold mt-4 flex justify-end">
  Total Amount: {calculateTotalAmount()}
</h3>

{lastTwoRows.length > 0 && (
        <div className="mt-6 p-4 border rounded-lg shadow">
          {lastTwoRows.map((row, rowIndex) => (
            <p key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <span key={cellIndex} className="mr-2">
                  {cell}
                </span>
              ))}
            </p>
          ))}
        </div>
      )}

      <div className="mt-4">
        {/* <label className="block mb-2">Shop Name:</label> */}
        <input
          type="text"
          value={shopName}
          placeholder='Shop Name and Mobile'
          onChange={(e) => setShopName(e.target.value)}
          className="border p-1 w-half mb-4"
        />
        {/* <label className="block mb-2">Google Pay Number:</label> */}
        <input
          type="text"
          value={shopNumber}
          placeholder='Shop Google Pay Number'
          onChange={(e) => setShopNumber(e.target.value)}
          className="border p-1 w-half mb-4"
        />
      </div>

      <button onClick={generatePDF} className="bg-blue-500 text-white p-2 rounded">
        Download PDF
      </button>
    </div>
  );
}

export default MaterialAmount;
